export const market_processed_table_keys = ['ath',
  'ath_change_percentage',
  'current_price',
  'high_24h',
  'low_24h',
  'market_cap',
  'price_change_percentage_7d_in_currency',
  'price_change_percentage_30d_in_currency',
  'total_volume']

export const market_stat_keys = ['circulating_supply',
  'total_supply',
  'public_interest_score',
  'market_cap_change_24h',
  'market_cap_change_percentage_24h',
  'price_change_percentage_14d',
  'price_change_percentage_1y',
  'price_change_percentage_200d',
  'price_change_percentage_24h',
  'price_change_percentage_30d',
  'price_change_percentage_60d',
  'price_change_percentage_7d'
]
